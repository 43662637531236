import { useState } from 'react';
import { useCreateDemoRequest } from '../mutations/createDemoRequest';
import { FiscalData } from './useFiscalDataForm';
import { RequestDocumentType } from '../../requests/types';

export type TargetType = 'PF' | 'PG';

export function useDemoFunnel({
  onDemoCreated,
}: {
  onDemoCreated: (requestId: string) => void;
}) {
  const [email, setEmail] = useState<string>('');
  const [fiscalData, setFiscalData] = useState<FiscalData>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const { mutate, isPending, isError } = useCreateDemoRequest({
    onSuccess: (data) => {
      onDemoCreated(data);
    },
  });

  const handleFiscalDataSubmit = (values: FiscalData) => {
    setFiscalData(values);
    forth();
  };

  const handleEmailSubmit = (email: string) => {
    setEmail(email);
    forth();
  };

  const handleDemoStart = (selectedDocs: RequestDocumentType[]) => {
    if (!fiscalData || !email)
      throw new Error(
        'Fiscal data and email must be set before starting the demo',
      );

    mutate({
      ...fiscalData,
      email,
      selectedDocs,
    });
  };

  const back = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const forth = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  return {
    activeStep,
    targetType: fiscalData?.target,
    back,
    forth,
    isPending,
    isError,
    handleEmailSubmit,
    handleFiscalDataSubmit,
    handleDemoStart,
  };
}
