import { config } from '@xtreamsrl/react-config';
import { Document, Platforms } from '../../requests/types';
import { TargetType } from '../hooks/useDemoFunnel';

export type GetSelectableDocsItem = {
  platform: Platforms;
  doc: Document;
};

export async function getSelectableDocs(
  targetType: TargetType,
): Promise<GetSelectableDocsItem[]> {
  return await fetch(`${config.baseUrl}/demo?targetType=${targetType}`, {
    method: 'GET',
  }).then((res) => res.json() as Promise<GetSelectableDocsItem[]>);
}
