import { CieIcon, SpidIcon } from '@cassetto/icons';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { useState } from 'react';
import { PageHeading } from '../../../_shared/components/PageHeading';
import {
  Platforms,
  platformToDisplayName,
  SupportedOtherAuth,
  SupportedProvider,
} from '../../types';
import { ProviderCard } from './ProviderCard';
import { avaliableSpids } from './spidsConfig';

export const OptionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing('sm-6'),
  cursor: 'pointer',
  borderRadius: '8px',
  borderColor: theme.palette.grey[3],
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '30vw',

  ':hover': {
    borderColor: theme.palette.brand[9],
    backgroundColor: theme.palette.brand[3],
  },
}));

export function ProviderSelector({
  selectProvider,
  platform,
  goBack,
}: {
  selectProvider: (provider: SupportedProvider) => void;
  platform: Platforms;
  goBack: () => void;
}) {
  const [furtherSelectionNeeded, setFurtherSelectionNeeded] = useState(false);

  return (
    <Flex
      direction="column"
      alignItems="center"
      gap={['md-2', 'md-2', 'xl-2']}
      flex={1}
      padding={[0, 0, 'lg-2']}
    >
      <PageHeading
        title={`Accedi ad ${platformToDisplayName[platform]}`}
        progress={25}
        goBack={goBack}
        subtitle={`
            Seleziona la modalità di accesso che preferisci. Per garantire la massima sicurezza, verrà richiesta un'autenticazione a due fattori.
            Assicurati di avere tutto il necessario per completare questo passaggio
          `}
      />

      <Flex direction="column" gap="sm-8" padding={['sm-8', 'sm-8', 0]}>
        <ProviderCard
          logo={<img src="/assets/spid.png" alt="SPID logo" />}
          name="SPID"
          description="SPID, il Sistema Pubblico di Identità Digitale, è il sistema di accesso che
                  consente di utilizzare, con un'identità digitale unica, i servizi online della
                  Pubblica Amministrazione e dei privati accreditati. Se sei già in possesso di
                  un'identità digitale, accedi con le credenziali del tuo gestore. Se non hai ancora
                  un'identità digitale, richiedila ad uno dei gestori."
          action={
            <Box style={{ position: 'relative' }}>
              <Button
                leftIcon={
                  <Icon>
                    <SpidIcon />
                  </Icon>
                }
                color="info"
                style={{ textTransform: 'none', width: '100%' }}
                onClick={() =>
                  setFurtherSelectionNeeded(!furtherSelectionNeeded)
                }
              >
                Entra con SPID
              </Button>
              <Box
                style={{
                  display: furtherSelectionNeeded ? 'flex' : 'none',
                  flexDirection: 'column',
                  position: 'absolute',
                  zIndex: 1,
                  border: 'solid 1px #DFE3E6',
                  borderRadius: '6px',
                  marginTop: '8px',
                  height: '30vh',
                  width: '100%',
                  overflow: 'scroll',
                  background: (th) => th.palette.grey['1'],
                }}
              >
                {avaliableSpids.map((spid, index) => (
                  <Box
                    key={spid.provider}
                    onClick={() => selectProvider(spid.provider)}
                    backgroundColor="grey.1"
                    borderBottom={
                      index === avaliableSpids.length - 1
                        ? 0
                        : 'solid 1px #DFE3E6'
                    }
                    paddingTop="sm-7"
                    paddingBottom="sm-5"
                    paddingX="sm-5"
                    textAlign="center"
                    style={{
                      cursor: 'pointer',
                      ':hover': { backgroundColor: 'grey.3' },
                    }}
                  >
                    <img
                      src={spid.logo}
                      alt={`${spid.provider} logo`}
                      style={{
                        height: '25px',
                        maxWidth: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          }
        />

        <ProviderCard
          logo={<img src="/assets/cie.png" alt="CIE logo" />}
          name="CIE"
          description="La Carta di Identità Elettronica (CIE) è il documento personale che attesta
                  l'identità del cittadino.
                  <br />
                  Dotata di microprocessore, oltre a comprovare l'identità personale, permette
                  l'accesso ai servizi digitali della Pubblica Amministrazione"
          action={
            <Button
              variant="filled"
              color="info"
              leftIcon={
                <Icon>
                  <CieIcon />
                </Icon>
              }
              style={{ textTransform: 'none' }}
              onClick={() => selectProvider(SupportedOtherAuth.cie)}
            >
              Entra con CIE
            </Button>
          }
        />
      </Flex>
    </Flex>
  );
}
