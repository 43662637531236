import React, { PropsWithChildren } from 'react';
import { useDemoFunnel } from '../../hooks/useDemoFunnel';
import { EmailForm } from '../EmailForm';
import { FiscalDataForm } from '../FiscalDataForm';
import { DemoDocumentsSelector } from '../DemoDocumentsSelector';
import { DocumentSelectorForm } from '../DocumentSelectorForm';

type Props = { onDemoCreated: (requestId: string) => void };
const DemoFunnel: React.FC<PropsWithChildren<Props>> = ({ onDemoCreated }) => {
  const {
    activeStep,
    back,
    handleEmailSubmit,
    handleFiscalDataSubmit,
    targetType,
    isPending,
    isError,
    handleDemoStart,
  } = useDemoFunnel({ onDemoCreated });

  return {
    0: (
      <EmailForm
        onSubmit={(email: string) => {
          handleEmailSubmit(email);
        }}
      />
    ),
    1: <FiscalDataForm onSubmit={handleFiscalDataSubmit} back={back} />,
    2: (
      <DemoDocumentsSelector targetType={targetType}>
        <DocumentSelectorForm
          isError={isError}
          isPending={isPending}
          onSubmit={handleDemoStart}
          back={back}
        />
      </DemoDocumentsSelector>
    ),
  }[activeStep];
};

DemoFunnel.displayName = 'DemoFunnel';
export default DemoFunnel;
