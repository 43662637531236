import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';

type Props = {
  qrImageSrc: string | undefined;
  valid: boolean;
};
const QRCodeDisplay: React.FC<Props> = ({ qrImageSrc, valid }) => {
  if (valid && qrImageSrc)
    return (
      <Flex
        flex={1}
        alignItems="center"
        justifyContent="flex-start"
        gap="md-2"
        direction="column"
      >
        <Typography
          variant="body/xxl/semibold"
          textAlign="start"
          style={{ width: '100%' }}
        >
          Entra con l'APP
        </Typography>
        <img style={{ maxHeight: '200px' }} src={qrImageSrc} alt="QR code" />
        <Flex direction="column" alignItems="center">
          <Typography variant="body/base/medium" whiteSpace="nowrap">
            {/*TODO: ADD CUSTOMISATION*/}
            Hai l'applicazione di autenticazione?
          </Typography>
          <Typography
            variant="body/base/regular"
            color="grey.11"
            textAlign="center"
          >
            Inquadra il QR Code con l’App per accedere rapidamente
          </Typography>
        </Flex>
      </Flex>
    );

  if (!valid && qrImageSrc)
    return (
      <Flex flex={1} alignItems="center" justifyContent="center">
        <Typography variant="body/base/regular">
          QR code non utilizzabile
        </Typography>
      </Flex>
    );

  return null;
};

QRCodeDisplay.displayName = 'QRCodeDisplay';
export default QRCodeDisplay;
