import { useContext } from 'react';
import { useParams } from '@xtreamsrl/react-routing';
import { RequestContext } from '../../demo/views/DemoRequestCreator/DemoRequestCreator';

export function useRequestId() {
  const requestIdFromContext = useContext<string | undefined>(RequestContext);
  const { requestId: requestIdFromUrl } = useParams<{ requestId: string }>();

  const requestId = requestIdFromContext ?? requestIdFromUrl;

  if (!requestId) throw new Error('requestId is required');
  return requestId;
}
