import { createContext, useContext } from 'react';
import { GetSelectableDocsItem } from '../apis/getSelectableDocs';

export const SelectableDocsContext = createContext<
  GetSelectableDocsItem[] | undefined
>(undefined);

export const useSelectableDocsContext = () => {
  const tempDocs = useContext(SelectableDocsContext);
  if (!tempDocs) {
    throw new Error(
      'useSelectableDocsContext must be used within a DemoDocumentsSelector',
    );
  }
  return tempDocs;
};
