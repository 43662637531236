import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import {
  ProgressBar,
  ProgressBarProps,
} from '@xtreamsrl/react-ui-kit/ProgressBar';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import useIsMobile from '../../hooks/useIsMobile';

const Placeholder: React.FC = () => <Box width="44px" height="44px" />;

type BaseProps = {
  title: string;
  subtitle?: string;
  hideOnDesktop?: boolean;
};

type HeadingProps = BaseProps & {
  progress?: undefined;
  goBack?: undefined;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
};

type StepperProps = BaseProps & {
  progress: ProgressBarProps['progress'];
  goBack?: () => void;
  leftButton?: undefined;
  rightButton?: React.ReactNode;
};

type Props = HeadingProps | StepperProps;
const PageHeading: React.FC<Props> = ({
  title,
  subtitle,
  progress,
  goBack,
  leftButton = <Placeholder />,
  rightButton = <Placeholder />,
  hideOnDesktop,
}) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Box width="100%">
        <Flex
          backgroundColor="white"
          gap="sm-4"
          alignItems="center"
          justifyContent="space-between"
        >
          {progress && goBack ? (
            <Button
              onlyIcon
              variant="plain"
              color="grey"
              icon={<Icon name="ChevronLeft" />}
              onClick={goBack}
            />
          ) : (
            leftButton
          )}
          <Typography variant="body/base/semibold" textAlign="center">
            {title}
          </Typography>
          {rightButton}
        </Flex>
        {progress && <ProgressBar progress={progress} />}
        {subtitle && (
          <Typography
            whiteSpace="pre-line"
            variant="body/xs/regular"
            textAlign="center"
            color="grey.11"
            style={{
              mt: (th) => th.spacing('sm-8'),
              paddingX: (th) => th.spacing('sm-8'),
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    );
  }

  if (hideOnDesktop) return null;

  return (
    <Flex direction="column" gap="sm-4">
      <Typography variant="header/sm/semibold" textAlign="center">
        {title}
      </Typography>
      <Typography
        variant="body/base/regular"
        textAlign="center"
        color="grey.11"
        whiteSpace="pre-line"
      >
        {subtitle}
      </Typography>
    </Flex>
  );
};

PageHeading.displayName = 'PageHeading';
export default PageHeading;
