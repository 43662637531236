import { useState, useEffect } from 'react';
import { useTheme } from '@xtreamsrl/react-ui-kit/theme';
// You can adjust this value for your mobile breakpoint

function useIsMobile(): boolean {
  const { breakpoints } = useTheme();
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < breakpoints.values.md,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoints.values.md);
    };

    // Trigger the check on component mount to ensure it's correct
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoints.values.md]);

  return isMobile;
}

export default useIsMobile;
