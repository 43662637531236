import { SpidIcon } from '@cassetto/icons';
import { FormTextInput } from '@xtreamsrl/react-form-input-kit/FormTextInput';
import { FormProvider } from '@xtreamsrl/react-forms';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Divider } from '@xtreamsrl/react-ui-kit/Divider';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { ProgressBar } from '@xtreamsrl/react-ui-kit/ProgressBar';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import useIsMobile from '../../../../_shared/hooks/useIsMobile';
import { useAuthenticationEventsHandler } from '../../../hooks/useAuthenticationEventsHandler';
import {
  SupportedOtherAuth,
  SupportedProvider,
  TwoFactorHandling,
} from '../../../types';
import { QRCodeDisplay } from '../QRCodeDisplay';

const Input = styled(FormTextInput)(() => ({
  width: '100%',
}));

type AuthenticationProps = {
  selectedProvider: SupportedProvider;
  setTwoFactorHandling: (twoFactorHandling: TwoFactorHandling) => void;
};

const AuthenticationStep: React.FC<AuthenticationProps> = ({
  selectedProvider,
  setTwoFactorHandling,
}: AuthenticationProps) => {
  const { loadingPhase, progress, qrImageSrc, isQrSrcValid, authForm, submit } =
    useAuthenticationEventsHandler({ setTwoFactorHandling });
  const isMobile = useIsMobile();

  const displayQRCode = isMobile ? false : qrImageSrc;

  return loadingPhase === 'init' ? (
    <Box flex={1} style={{ background: 'white' }}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="sm-6"
        height="328px"
      >
        <Typography variant="body/xxl/semibold">Modalità di accesso</Typography>
        <Box width="50%">
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */}
          <ProgressBar type="default" progress={progress as any} />
        </Box>
        <Typography variant="body/base/medium" color="grey.11">
          Recuperando le modalità di accesso
        </Typography>
      </Flex>
    </Box>
  ) : (
    <FormProvider enableDevTools={false} {...authForm.formProps}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          submit().catch((error) => {
            console.error('Error sending credentials', error);
          });
        }}
      >
        <Flex
          mt="sm-8"
          paddingBottom="sm-8"
          justifyContent={displayQRCode ? 'space-between' : 'center'}
          flex={1}
        >
          <Flex direction="column" justifyContent="space-between" flex={1}>
            {!isMobile && (
              <Box mb="md-2">
                <Typography variant="body/xxl/semibold">
                  Entra con le tue credenziali
                </Typography>
              </Box>
            )}
            <Flex
              direction="column"
              gap="md-2"
              style={{ minWidth: '300px' }}
              width={!displayQRCode && !isMobile ? '500px' : undefined}
            >
              <Input
                label="Username"
                labelProps={{ size: 'md' }}
                size="lg"
                name="username"
                placeholder="Inserisci username"
              />
              <Input
                type="password"
                label="Password"
                labelProps={{ size: 'md' }}
                size="lg"
                name="password"
                placeholder="Inserisci password"
              />
              <Button
                type="submit"
                variant="filled"
                color="info"
                loading={loadingPhase === 'verifyingCredentials'}
                leftIcon={
                  <Icon>
                    <SpidIcon />
                  </Icon>
                }
                style={{
                  textTransform: 'none',
                }}
              >
                {`Entra con
                ${selectedProvider === SupportedOtherAuth.cie ? 'CIE' : 'SPID'}`}
              </Button>
            </Flex>
          </Flex>

          {displayQRCode ? (
            <>
              <Box mx="lg-2" style={{ '.MuiDivider-root': { height: '100%' } }}>
                <Divider direction="vertical" />
              </Box>

              <QRCodeDisplay qrImageSrc={displayQRCode} valid={isQrSrcValid} />
            </>
          ) : null}
        </Flex>
      </form>
    </FormProvider>
  );
};

AuthenticationStep.displayName = 'AuthenticationStep';
export default AuthenticationStep;
