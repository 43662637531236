import React, { PropsWithChildren } from 'react';
import { Platforms } from '../../types';
import { PlatformsView } from '../../views/PlatformsView';
import Login from '../Login';

type Props = {
  nextStep: () => void;
  setToken: (token: string) => void;
};

const MultiplatformLogin: React.FC<PropsWithChildren<Props>> = ({
  nextStep,
  setToken, // This will be used only to get to the "Invio Documenti step" when the user decides to
}) => {
  const [activePlatform, setActivePlatform] = React.useState<
    Platforms | undefined
  >(undefined);
  const [lastSuccessfullyAccessed, setLastSuccessfullyAccessed] =
    React.useState<Platforms | undefined>();

  if (!activePlatform) {
    return (
      <PlatformsView
        setActivePlatform={setActivePlatform}
        lastSuccessfullyAccessedPlatform={lastSuccessfullyAccessed}
        endProcedure={nextStep}
      />
    );
  }

  return (
    <Login
      platform={activePlatform}
      unsetPlatform={() => setActivePlatform(undefined)}
      onLoginSuccess={(token: string) => {
        setToken(token);
        setLastSuccessfullyAccessed(activePlatform);
        setActivePlatform(undefined);
      }}
      onLoginFailure={() => {
        setActivePlatform(undefined);
      }}
    />
  );
};

MultiplatformLogin.displayName = 'MultiplatformLogin';
export default MultiplatformLogin;
