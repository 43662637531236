import { useState } from 'react';
import { useForm } from '@xtreamsrl/react-forms';
import { TargetType } from './useDemoFunnel';
import * as yup from 'yup';

export const tabs: Record<number, { code: TargetType; name: string }> = {
  0: {
    code: 'PF',
    name: 'Persona fisica',
  },
  1: {
    code: 'PG',
    name: 'Persona legale',
  },
};

const fiscalCodeRegex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;
const vatNumberRegex = /^[0-9]{11}$/;

const validationSchema = yup.object().shape({
  target: yup
    .mixed<TargetType>()
    .oneOf(['PF', 'PG'])
    .required('errors.required'),
  fiscalCode: yup.string().when('target', {
    is: 'PF',
    then: () =>
      yup
        .string()
        .transform((value) => (value as string).toUpperCase())
        .matches(fiscalCodeRegex, 'errors.fiscalCode')
        .required('errors.required'),
    otherwise: () =>
      yup
        .string()
        .optional()
        .transform(() => undefined),
  }),
  vatNumber: yup.string().when('target', {
    is: 'PG',
    then: () =>
      yup
        .string()
        .matches(vatNumberRegex, 'errors.vatNumber')
        .required('errors.required'),
    otherwise: () =>
      yup
        .string()
        .optional()
        .transform(() => undefined),
  }),
  name: yup.string().when('target', {
    is: 'PG',
    then: () => yup.string().required('errors.required'),
    otherwise: () =>
      yup
        .string()
        .optional()
        .transform(() => undefined),
  }),
});

export type FiscalData = yup.InferType<typeof validationSchema>;

export function useFiscalDataForm(onSubmit: (fiscalData: FiscalData) => void) {
  const [currentTab, setCurrentTab] = useState(0);

  const fiscalDataForm = useForm({
    initialValues: {
      target: 'PF' as TargetType,
      name: '',
      fiscalCode: '',
      vatNumber: '',
    },
    validationSchema: validationSchema,
    mode: 'onBlur',
  });

  const handleFiscalDataSubmit = fiscalDataForm.formProps.handleSubmit(
    (values, event) => {
      event?.preventDefault();
      onSubmit(values);
    },
  );

  return { currentTab, fiscalDataForm, handleFiscalDataSubmit, setCurrentTab };
}
