import { useQuery } from '@tanstack/react-query';
import { config } from '@xtreamsrl/react-config';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { useEffect } from 'react';
import { PageHeading } from '../../_shared/components/PageHeading';
import { isInFinalStatus } from '../../_shared/utils/utils';
import { optionsBuilder } from '../asyncHooks/getRequestInfo';
import Callout from '../components/Callout/Callout';
import { PlatformWithDocsRecap } from '../components/PlatformWithDocsRecap';
import { useRequestId } from '../hooks/useRequestId';
import { Platforms } from '../types';
import { isStateCreatedOrAuthInProgress, shouldRefetch } from '../utils';

const REFETCH_INTERVAL = 3000;

export function PlatformsView(props: {
  setActivePlatform: (platform: Platforms | undefined) => void;
  lastSuccessfullyAccessedPlatform: Platforms | undefined;
  endProcedure: () => void;
}) {
  const requestId = useRequestId();
  const { data, refetch } = useQuery({
    ...optionsBuilder(requestId),
    select: (data) => ({
      isLinkShareable: data.isLinkShareable,
      requestFrom: data.requestFrom,
      platformAndDocuments: data.platformAndDocuments,
      platformStatus: data.platformStatus,
      downloadReport: data.downloadReport,
    }),
    staleTime: REFETCH_INTERVAL,
  });
  if (!data) throw new Error('Request is required');

  useEffect(() => {
    if (
      shouldRefetch({
        lastAccessedPlatform: props.lastSuccessfullyAccessedPlatform,
        platformStatuses: data.platformStatus,
        platformAndDocuments: data.platformAndDocuments,
      })
    ) {
      const interval = setInterval(() => {
        void refetch();
      }, REFETCH_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [
    refetch,
    data.platformAndDocuments,
    data.platformStatus,
    props.lastSuccessfullyAccessedPlatform,
  ]);

  return (
    <Flex
      flex={1}
      direction="column"
      padding={[0, 0, 'lg-2']}
      gap={['md-2', 'md-2', 'xl-2']}
    >
      {/* TODO: Add info right-button component here when ready */}
      <PageHeading
        title="Accesso e Recupero Documenti"
        subtitle="I documenti richiesti potrebbero essere disponibili su diverse
          piattaforme. Sarà necessario accedere a ciascuna piattaforma
          separatamente per recuperarli"
      />

      <Flex direction="column" gap="sm-8" padding={['sm-8', 'sm-8', 0]}>
        {data.platformAndDocuments.map((p, i) => (
          <PlatformWithDocsRecap
            key={p.platform}
            requestFrom={data.requestFrom}
            platform={p.platform}
            // the ! is safe because of the check performed in the api when the data is fetched
            status={data.platformStatus[p.platform]!.status}
            documents={p.documents}
            downloadReport={data.downloadReport}
            setActivePlatform={props.setActivePlatform}
            hasJustBeenAccessed={
              p.platform === props.lastSuccessfullyAccessedPlatform
            }
            open={
              p.platform !== props.lastSuccessfullyAccessedPlatform &&
              isStateCreatedOrAuthInProgress(
                data.platformStatus[p.platform]!.status,
              ) &&
              !data.platformAndDocuments
                .slice(0, i)
                .some(
                  (prevP) =>
                    isStateCreatedOrAuthInProgress(
                      data.platformStatus[prevP.platform]!.status,
                    ) &&
                    prevP.platform !== props.lastSuccessfullyAccessedPlatform,
                )
            }
          />
        ))}
      </Flex>

      {data.isLinkShareable &&
        data.platformAndDocuments
          .map((p) => p.platform)
          .some((p) => !isInFinalStatus(data.platformStatus[p]!.status)) && (
          <Callout
            status="info"
            text="Non disponi delle credenziali per accedere a queste piattaforme?"
          >
            <Typography variant="body/xs/regular" color="grey.11">
              Se non si possiedono le credenziali per accedere alle piattaforme,
              potrebbe essere necessario condividere il link con un collega che
              disponga dei permessi necessari.
            </Typography>
            <Box>
              <Button
                variant="plain"
                color="info"
                size="sm"
                rightIcon={<Icon name="Paperclip" />}
                onClick={() => {
                  void navigator.clipboard.writeText(window.location.href);
                }}
              >
                Condividi Link alla Piattaforma
              </Button>
            </Box>
          </Callout>
        )}
      <Flex
        direction="column"
        alignSelf="center"
        alignItems="center"
        gap="sm-8"
      >
        {config.isDemo && (
          <Typography variant="body/xs/bold" color="grey.11" textAlign="center">
            Quando NON in DEMO
          </Typography>
        )}
        <Typography
          variant="body/xs/regular"
          color="grey.11"
          textAlign="center"
        >
          I documenti verranno condivisi con {data.requestFrom.name}{' '}
          progressivamente e in maniera automatica dopo l'accesso alle
          rispettive piattaforme.
        </Typography>
        <Box>
          {data.platformAndDocuments.every(
            (p) => isInFinalStatus(data.platformStatus[p.platform]!.status), // the ! is safe because of the check performed in the api when the data is fetched
          ) ? (
            <Button
              onClick={props.endProcedure}
              style={{ textTransform: 'none' }}
            >
              {config.isDemo
                ? `Visualizza i documenti scaricati`
                : `Termina procedura in sicurezza`}
            </Button>
          ) : config.isDemo ? (
            <>
              <Typography
                variant="body/xs/bold"
                color="grey.11"
                textAlign="center"
              >
                Al momento SOLO in DEMO
              </Typography>
              <Typography
                variant="body/xs/regular"
                color="grey.11"
                textAlign="center"
              >
                Avrai modo di visualizzare i documenti scaricati una volta
                terminata la procedura su tutte le piattaforme.
              </Typography>
            </>
          ) : null}
        </Box>
      </Flex>
    </Flex>
  );
}
