import { createQuery } from '@xtreamsrl/react-query';
import { TargetType } from '../hooks/useDemoFunnel';
import { getSelectableDocs } from '../apis/getSelectableDocs';

const getDocumentsListQuery = createQuery(
  ({ targetType }: { targetType: TargetType }) => ({
    queryKey: ['selectableDocs', targetType],
    queryFn: () => getSelectableDocs(targetType),
  }),
);

export const useSelectableDocs = (targetType: TargetType) =>
  getDocumentsListQuery.hook({ targetType });
