import React, { PropsWithChildren } from 'react';
import { FormProvider } from '@xtreamsrl/react-forms';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { FormTextInput } from '@xtreamsrl/react-form-input-kit/FormTextInput';
import Callout from '../../../requests/components/Callout/Callout';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { useEmailForm } from '../../hooks/useEmailForm';

type Props = {
  onSubmit: (email: string) => void;
};

const EmailForm: React.FC<PropsWithChildren<Props>> = ({ onSubmit }) => {
  const { emailForm, handleEmailSubmit } = useEmailForm(onSubmit);

  return (
    <FormProvider enableDevTools={false} {...emailForm.formProps}>
      <form
        style={{ width: '100%' }}
        onSubmit={(event) => {
          handleEmailSubmit(event).catch((e) => {
            console.error('Error performing email submission', e);
          });
        }}
      >
        <Flex direction="column" gap="sm-4">
          <Typography variant="body/xl/semibold">
            Prendi decisioni migliori con i dati fiscali dei tuoi clienti.
          </Typography>
          <Typography variant="body/base/regular">
            Scopri quanto è facile e veloce recuperare un ampio set documentale
            dai tuoi utenti, lasciandoli autenticare tramite{' '}
            <strong>SPID</strong> o <strong>CIE</strong>. In ambiente reale (non
            demo) i dati verrebbero poi elaborati ed inviati all'istituto
            bancario o alla fintech che sta offrendo la soluzione tramite
            CassettoAPI.
            <br />
            Inserisci il tuo indirizzo e-mail per avviare la dimostrazione.
          </Typography>
          <FormTextInput
            label="E-mail"
            name="email"
            placeholder="example@cassettoapi.it"
          />
          <Callout status="info" text="Informazioni sull'utilizzo dei dati">
            <Typography variant="body/sm/regular">
              I documenti scaricati saranno eliminati immediatamente al termine
              della sessione e non utilizzati o memorizzati in alcun modo.
              Proseguendo, acconsenti all'utilizzo dei dati esclusivamente a
              fini dimostrativi.
            </Typography>
          </Callout>
          <Box width="100%" textAlign="right">
            <Button type="submit">Avanti</Button>
          </Box>
        </Flex>
      </form>
    </FormProvider>
  );
};

EmailForm.displayName = 'EmailForm';
export default EmailForm;
