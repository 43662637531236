import { config } from '@xtreamsrl/react-config';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import { Document } from '../types';

export const DocumentFilterDisplay: React.FC<{ document: Document }> = ({
  document,
}) => {
  if (document.type === 'ANAGRAFICA')
    return (
      !config.isDemo && (
        <Typography color="grey.11" variant="body/xs/regular">
          Ragione Sociale, PIVA
        </Typography>
      )
    );

  if (document.type === 'F24_RIEPILOGATIVO' && document.filters?.where?.or) {
    const filters = document.filters.where.or;
    return (
      <Typography variant="body/xs/regular">
        {filters.map((filter, index) => (
          <Flex
            direction="column"
            gap="sm-2"
            justifyItems="center"
            key={`riepilogativo-${index}`}
          >
            <Typography
              color="grey.11"
              variant="body/xs/regular"
              whiteSpace="break-spaces"
              style={{ display: 'ruby' }}
            >
              - Dei Codici Tributo{' '}
              <Typography color="grey.11" variant="body/xs/semibold">
                {filter.codiceTributo.length
                  ? filter.codiceTributo.join(', ')
                  : ''}
                {filter.prefisso ? `con Prefisso ${filter.prefisso} ` : ' '}
              </Typography>
              del periodo dal{' '}
              <Typography color="grey.11" variant="body/xs/semibold">
                {new Date(filter.dates.from).toLocaleDateString('it-IT')}{' '}
              </Typography>
              al{' '}
              <Typography color="grey.11" variant="body/xs/semibold">
                {new Date(filter.dates.to).toLocaleDateString('it-IT')}
              </Typography>
            </Typography>
          </Flex>
        ))}
      </Typography>
    );
  }

  if (document.filters?.dates?.type === 'years') {
    return (
      <Typography color="grey.11" variant="body/xs/regular">
        Degli anni: {document.filters.dates.years.join(', ')}
      </Typography>
    );
  }

  if (document.filters?.dates?.type === 'lastNYears') {
    return (
      <Typography color="grey.11" variant="body/xs/regular">
        {document.filters.dates.numberOfYears === 1
          ? "Dell'ultimo anno"
          : `Degli ultimi ${document.filters.dates.numberOfYears} anni`}
      </Typography>
    );
  }

  if (document.filters?.dates?.type === 'dateRange') {
    return (
      <Typography
        color="grey.11"
        variant="body/xs/regular"
        whiteSpace="break-spaces"
        style={{ display: 'ruby' }}
      >
        Dal{' '}
        <Typography color="grey.11" variant="body/xs/semibold">
          {new Date(document.filters.dates.from).toLocaleDateString('it-IT')}{' '}
        </Typography>
        al{' '}
        <Typography color="grey.11" variant="body/xs/semibold">
          {new Date(document.filters.dates.to).toLocaleDateString('it-IT')}
        </Typography>
      </Typography>
    );
  }

  if (document.filters?.dates?.type === 'lastNMonths') {
    return (
      <Typography color="grey.11" variant="body/xs/regular">
        {document.filters.dates.numberOfMonths === 1
          ? "Dell'ultimo mese"
          : `Degli ultimi ${document.filters.dates.numberOfMonths} mesi`}
      </Typography>
    );
  }

  return null;
};
