import { CassettoApiIcon } from '@cassetto/icons';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import React, { useState } from 'react';
import FullScreenCard from 'src/requests/components/FullScreenCard';
import RequestDataRetriever from '../../../requests/views/DataRequest';
import { Ribbon } from '../../components/Ribbon';
import { DemoFunnel } from '../../components/DemoFunnel';
import { DemoFooter } from '../../components/DemoFooter';

export const RequestContext = React.createContext<string | undefined>(
  undefined,
);

const DemoRequestCreator: React.FC = () => {
  const [requestId, setRequestId] = useState<string | undefined>(undefined);

  if (requestId) {
    return (
      <RequestContext.Provider value={requestId}>
        <Ribbon text="DEMO" />
        <RequestDataRetriever />
        <DemoFooter content="Stai agendo come UTENTE" color="green" />
      </RequestContext.Provider>
    );
  }

  return (
    <>
      <Ribbon text="DEMO" />
      <FullScreenCard>
        <Flex direction="column" gap="md-1" alignItems="center" width="100%">
          <CassettoApiIcon />
          <DemoFunnel onDemoCreated={setRequestId} />
        </Flex>
      </FullScreenCard>
      <DemoFooter content="Stai agendo come BANCA" color="blue" />
    </>
  );
};

DemoRequestCreator.displayName = 'DemoRequestCreator';
export default DemoRequestCreator;
