import React, { PropsWithChildren } from 'react';
import { FormProvider } from '@xtreamsrl/react-forms';
import { Tabs } from '@xtreamsrl/react-ui-kit/Tabs';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { FormTextInput } from '@xtreamsrl/react-form-input-kit/FormTextInput';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import {
  FiscalData,
  tabs,
  useFiscalDataForm,
} from '../../hooks/useFiscalDataForm';

type Props = {
  onSubmit: (fiscalData: FiscalData) => void;
  back: () => void;
};

export function DemoButtonLine({
  back,
  isLoading,
  submitMessage,
}: {
  back: () => void;
  isLoading?: boolean;
  submitMessage: string;
}) {
  return (
    <Flex justifyContent="space-between">
      <Button variant="outlined" color="grey" onClick={back}>
        Indietro
      </Button>
      <Button type="submit" loading={!!isLoading}>
        {submitMessage}
      </Button>
    </Flex>
  );
}

const FiscalDataForm: React.FC<PropsWithChildren<Props>> = ({
  onSubmit,
  back,
}) => {
  const { fiscalDataForm, handleFiscalDataSubmit, currentTab, setCurrentTab } =
    useFiscalDataForm(onSubmit);

  return (
    <FormProvider enableDevTools={false} {...fiscalDataForm.formProps}>
      <form
        style={{ width: '100%' }}
        onSubmit={(event) => {
          handleFiscalDataSubmit(event).catch((e) => {
            console.error('Error performing fiscal data submission', e);
          });
        }}
      >
        <Tabs
          currentTab={currentTab}
          fullWidth
          onChange={(_, t) => {
            setCurrentTab(t);
            fiscalDataForm.formProps.setValue('target', tabs[t].code);
          }}
        >
          <Tabs.Tab value={0}>{tabs[0].name}</Tabs.Tab>
          <Tabs.Tab value={1}>{tabs[1].name}</Tabs.Tab>
        </Tabs>
        {Object.keys(tabs).map((key) => {
          const index = Number(key);
          return (
            <Tabs.Panel index={index} currentTab={currentTab} key={index}>
              <Flex direction="column" marginTop="md-1">
                {tabs[index].code === 'PF' ? (
                  <FormTextInput
                    className="uppercase"
                    autoComplete="on"
                    label="Codice fiscale"
                    name="fiscalCode"
                  />
                ) : (
                  <>
                    <FormTextInput
                      autoComplete="on"
                      label="Partita IVA"
                      name="vatNumber"
                    />
                    <FormTextInput label="Nome Azienda" name="name" />
                  </>
                )}
                <DemoButtonLine
                  back={back}
                  submitMessage="Scegli il set documentale"
                />
              </Flex>
            </Tabs.Panel>
          );
        })}
      </form>
    </FormProvider>
  );
};

FiscalDataForm.displayName = 'FiscalDataForm';
export default FiscalDataForm;
