import { useForm } from '@xtreamsrl/react-forms';
import { Document, RequestDocumentType } from '../../requests/types';
import * as yup from 'yup';

import { useSelectableDocsContext } from './useSelectableDocsContext';

const createInitialValues = (documents: Document[]) => {
  if (!documents) return {} as Record<Partial<RequestDocumentType>, boolean>;
  return documents.reduce<Record<Partial<RequestDocumentType>, boolean>>(
    (acc, doc) => {
      acc[doc.type] = true;
      return acc;
    },
    {} as Record<Partial<RequestDocumentType>, boolean>,
  );
};
const validationSchema = yup
  .object()
  .shape<Record<string, yup.BooleanSchema>>({})
  .test('at-least-one', 'At least one document must be selected', (value) => {
    return Object.values(value).some((val) => val === true);
  });

export function useDocumentSelectorForm(
  onSubmit: (selectedDocs: RequestDocumentType[]) => void,
) {
  const selectableDocuments = useSelectableDocsContext();
  const initialValues = createInitialValues(
    selectableDocuments.map((doc) => doc.doc),
  );

  const docsForm = useForm<Record<Partial<RequestDocumentType>, boolean>>({
    initialValues,
    mode: 'onBlur',
    validationSchema,
  });

  const handleSubmit = docsForm.formProps.handleSubmit((values, event) => {
    event?.preventDefault();
    const selectedDocs = Object.entries(values)
      .filter(([_key, value]) => value)
      .map(([key]) => key as RequestDocumentType);
    onSubmit(selectedDocs);
  });

  return { docsForm, handleSubmit, selectableDocuments };
}
