import { config } from '@xtreamsrl/react-config';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Navigate, Route, Routes } from 'react-router-dom';
import useIsMobile from './_shared/hooks/useIsMobile';
import DemoRequestCreator from './demo/views/DemoRequestCreator';
import Callout from './requests/components/Callout/Callout';
import FullScreenCard from './requests/components/FullScreenCard';
import RequestDataRetriever from './requests/views/DataRequest';

const App = () => {
  const isMobile = useIsMobile();

  // TODO: remove this when mobile version is ready
  if (isMobile) {
    return (
      <Flex direction="column" gap="md-1" padding="md-2">
        <Callout text="App non disponibile su mobile" status="info">
          <Typography variant="body/sm/regular">
            Per utilizzare l'applicazione ti chiediamo di accedere da un
            dispositivo desktop.
          </Typography>
        </Callout>
      </Flex>
    );
  }

  return (
    <Routes>
      {config.isDemo ? (
        <>
          <Route path="demo" element={<DemoRequestCreator />} />
          <Route path="*" element={<Navigate to="/demo" replace={true} />} />
        </>
      ) : (
        <>
          <Route
            path="/requests/:requestId"
            element={<RequestDataRetriever />}
          />
          <Route
            path="*"
            element={
              <FullScreenCard>
                <Flex direction="column" gap="md-1">
                  <Typography variant="body/xl/bold">
                    Errore inaspettato
                  </Typography>

                  <Typography variant="body/sm/regular">
                    C'è stato un errore durante il caricamento, prova a
                    ricaricare la pagina. Se il problema persiste controlla di
                    star accedendo alla pagina corretta. Altrimenti contatta il
                    supporto.
                  </Typography>
                </Flex>
              </FullScreenCard>
            }
          />
        </>
      )}
    </Routes>
  );
};

export default App;
