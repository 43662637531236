import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';

export const BadgeContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'total',
})<{
  state: 'success' | 'error' | 'warning' | 'loading';
}>(({ theme, state }) => {
  const baseColor = {
    success: theme.palette.green!,
    error: theme.palette.red!,
    warning: theme.palette.warning,
    loading: theme.palette.info,
  }[state];

  return {
    padding: `${theme.spacing('sm-1')} ${theme.spacing('sm-3')}`,
    borderRadius: '99px',
    backgroundColor: baseColor[3],
    color: `${baseColor[10]}`,
  };
});

const BadgeTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'state',
})<{
  state: 'success' | 'error' | 'warning' | 'loading';
}>(({ theme, state }) => {
  const baseColor = {
    success: theme.palette.green!,
    error: theme.palette.red!,
    warning: theme.palette.warning,
    loading: theme.palette.info,
  }[state];

  return {
    color: `${baseColor[10]} !important`,
  };
});

export const Badge: React.FC<{
  state: 'success' | 'error' | 'warning' | 'loading';
}> = ({ state }) => {
  const content = {
    success: 'Recupero Documenti Completato',
    error: 'Recupero Documenti Fallito',
    warning: 'Recupero Documenti Parziale',
    loading: 'Recupero Documenti in Corso',
  }[state];

  return (
    <BadgeContainer state={state} height="fit-content">
      <Flex gap="sm-2">
        <BadgeTypography state={state} variant="body/xs/medium">
          {content}
        </BadgeTypography>
      </Flex>
    </BadgeContainer>
  );
};
