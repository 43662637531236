import { Box } from '@xtreamsrl/react-ui-kit/Box';
import React, { PropsWithChildren } from 'react';

const FullScreenCard: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      style={{
        minHeight: '100vh',
        minWidth: '100%',
      }}
      backgroundColor={'brand.12'}
      padding={[0, 0, 'xl-2', 'xl-2']}
    >
      <Box
        backgroundColor="background.default"
        border="1px solid"
        paddingTop="xl-1"
        paddingLeft={['sm-8', 'md-8']}
        paddingRight={['sm-8', 'md-8']}
        paddingBottom={['sm-8', 'md-8']}
        height="100%"
        style={(th) => ({
          display: 'flex',
          borderColor: 'grey.1',
          borderRadius: '8px',
          maxWidth: '900px',
          [th.breakpoints.down('md')]: {
            borderRadius: '0px',
            minHeight: '100vh',
          },
          margin: 'auto',
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

FullScreenCard.displayName = 'FullScreenCard';
export default FullScreenCard;
