import { SpidIcon } from '@cassetto/icons';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Card } from '@xtreamsrl/react-ui-kit/Card';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';

type BasicProps = {
  logo: React.ReactNode;
  name: string;
  description: string;
  onSelect: () => void;
  action?: undefined;
};

type ProviderWithCustomButtonProps = {
  logo: React.ReactNode;
  name: string;
  description: string;
  onSelect?: undefined;
  action: React.ReactNode;
};

type Props = ProviderWithCustomButtonProps | BasicProps;
const ProviderCard: React.FC<Props> = ({
  logo,
  action,
  description,
  name,
  onSelect,
}) => {
  return (
    <Card style={{ overflow: 'unset' }}>
      <Card.Content>
        <Flex
          style={(th) => ({
            [th.breakpoints.down('md')]: { flexDirection: 'column' },
          })}
          gap="sm-8"
        >
          <Box height="44px" style={{ '> *': { height: '44px' } }}>
            {logo}
          </Box>

          <Flex direction="column" gap="sm-8">
            <Typography variant="body/xxl/semibold">{name}</Typography>
            <Typography variant="body/sm/regular" color="grey.11">
              {description}
            </Typography>
          </Flex>
        </Flex>

        <Flex
          justifyContent="end"
          mt={['md-2', 'md-2', 'sm-5']}
          style={(th) => ({
            [th.breakpoints.down('md')]: {
              '> *': { width: '100%' },
            },
          })}
        >
          {action ?? (
            <Button
              style={{ textTransform: 'none' }}
              leftIcon={
                <Icon>
                  <SpidIcon />
                </Icon>
              }
              color="info"
              onClick={onSelect}
            >{`Entra con ${name}`}</Button>
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};

ProviderCard.displayName = 'ProviderCard';
export default ProviderCard;
