import * as yup from 'yup';
import { useForm } from '@xtreamsrl/react-forms';

const mailValidationSchema = yup.object().shape({
  email: yup.string().email('errors.email').required('errors.required'),
});

export function useEmailForm(onSubmit: (email: string) => void) {
  const emailForm = useForm({
    initialValues: {
      email: '',
    },
    validationSchema: mailValidationSchema,
    mode: 'onBlur',
  });

  const handleEmailSubmit = emailForm.formProps.handleSubmit(
    (values, event) => {
      event?.preventDefault();
      onSubmit(values.email);
    },
  );
  return { emailForm, handleEmailSubmit };
}
