import React, { PropsWithChildren } from 'react';
import { useSelectableDocs } from '../../queries/getSelectableDocs';
import { TargetType } from '../../hooks/useDemoFunnel';
import { useNonUndefinedTargetType } from '../../hooks/useNonUndefinedTargetType';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import Callout from '../../../requests/components/Callout/Callout';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { SelectableDocsContext } from '../../hooks/useSelectableDocsContext';

interface DocumentSelectorProps {
  targetType: TargetType | undefined;
}

const DemoDocumentsByTargetTypeProvider: React.FC<
  PropsWithChildren<DocumentSelectorProps>
> = ({ targetType: targetTypeTmp, children }) => {
  const targetType = useNonUndefinedTargetType(targetTypeTmp);
  const {
    data: selectableDocuments,
    error,
    isLoading,
  } = useSelectableDocs(targetType);

  if (selectableDocuments)
    return (
      <SelectableDocsContext.Provider value={selectableDocuments}>
        <Flex direction="column" gap="md-1" width="100%">
          {children}
        </Flex>
      </SelectableDocsContext.Provider>
    );

  if (isLoading)
    return (
      <Callout status="info" text="Caricamento">
        <Flex gap="sm-8">
          <Spinner state="indeterminate" size={20} />
          <Typography variant="body/sm/regular">
            Caricamento dei documenti associati al tipo di utente in corso... Si
            prega di attendere un istante, la lista per la selezione sarà
            disponibile a breve.
          </Typography>
        </Flex>
      </Callout>
    );

  if (error)
    return (
      <Callout status="error" text="Errore">
        <Typography variant="body/sm/regular">
          Si è verificato un errore durante il caricamento delle tipologie di
          documento. Riprova...
        </Typography>
      </Callout>
    );
};

DemoDocumentsByTargetTypeProvider.displayName = 'DemoDocumentsSelector';
export default DemoDocumentsByTargetTypeProvider;
