import { config } from '@xtreamsrl/react-config';
import * as yup from 'yup';
import { FiscalData } from '../hooks/useFiscalDataForm';
import { RequestDocumentType } from '../../requests/types';

type CreateDemoRequestData = {
  email: string;
  selectedDocs: RequestDocumentType[];
} & FiscalData;

const createDemoRequestResponseSchema = yup.object().shape({
  id: yup.string().required(),
  url: yup.string().required(),
});

type CreateDemoRequestResponse = yup.InferType<
  typeof createDemoRequestResponseSchema
>;

export async function createDemoRequest(
  data: CreateDemoRequestData,
): Promise<string> {
  const input = {
    email: data.email,
    target: {
      type: data.target,
      ...(data.target === 'PF'
        ? { fiscalCode: data.fiscalCode }
        : { vatNumber: data.vatNumber, name: data.name }),
    },
    selectedDocs: data.selectedDocs,
  };

  const beValues = await fetch(`${config.baseUrl}/demo/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      charset: 'utf-8',
    },
    body: JSON.stringify(input),
  }).then((res) => res.json() as Promise<CreateDemoRequestResponse>);

  await createDemoRequestResponseSchema.validate(beValues);

  return beValues.id;
}
