import { createTheme } from '@xtreamsrl/react-ui-kit/theme';
import { getPaletteFromCompanyColor } from './_shared/utils/getPaletteFromCompanyColor';

export function createCustomTheme(brandColor = '#0066CC') {
  const generatedTheme = getPaletteFromCompanyColor({
    appearance: 'light',
    accent: brandColor,
    gray: '#889096',
    background: '#FFFFFF',
  });
  return createTheme({
    typography: {
      fontFamily: 'Inter',
    },
    palette: {
      background: {
        default: generatedTheme.background,
      },
      brand: {
        '1': generatedTheme.accentScale[0],
        '2': generatedTheme.accentScale[1],
        '3': generatedTheme.accentScale[2],
        '4': generatedTheme.accentScale[3],
        '5': generatedTheme.accentScale[4],
        '6': generatedTheme.accentScale[5],
        '7': generatedTheme.accentScale[6],
        '8': generatedTheme.accentScale[7],
        '9': generatedTheme.accentScale[8],
        '10': generatedTheme.accentScale[9],
        '11': generatedTheme.accentScale[10],
        '12': generatedTheme.accentScale[11],
      },
      info: {
        '1': '#FCFDFE',
        '2': '#F6FAFF',
        '3': '#EAF3FE',
        '4': '#DCEBFF',
        '5': '#CAE2FF',
        '6': '#B6D5FE',
        '7': '#9DC3F6',
        '8': '#78ABEE',
        '9': '#0066CC',
        '10': '#0057BC',
        '11': '#0769CF',
        '12': '#0E325E',
      },
      grey: {
        '1': generatedTheme.grayScale[0],
        '2': generatedTheme.grayScale[1],
        '3': generatedTheme.grayScale[2],
        '4': generatedTheme.grayScale[3],
        '5': generatedTheme.grayScale[4],
        '6': generatedTheme.grayScale[5],
        '7': generatedTheme.grayScale[6],
        '8': generatedTheme.grayScale[7],
        '9': generatedTheme.grayScale[8],
        '10': generatedTheme.grayScale[9],
        '11': generatedTheme.grayScale[10],
        '12': generatedTheme.grayScale[11],
      },
    },
    components: {
      // TODO: workaround until we expose fullWidth on ui-kit Modal
      MuiDialog: {
        styleOverrides: {
          root: ({ theme }) => ({
            '.MuiDialog-paper': {
              padding: theme.spacing('md-4'),
              [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                width: '100vw',
                height: '100vh',
                margin: 0,
                borderRadius: 0,
                maxHeight: 'unset',
                maxWidth: 'unset',
                padding: 0,
              },
            },
          }),
        },
      },
      // NOTE: Converting desktop variants into mobile variants
      MuiTypography: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            ...(ownerState.variant === 'body/sm/regular' && {
              [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                ...theme.typography['body/xs/regular'],
              },
            }),
            ...(ownerState.variant === 'body/xxl/semibold' && {
              [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                ...theme.typography['body/lg/semibold'],
              },
            }),
            ...(ownerState.variant === 'body/xl/medium' && {
              [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                ...theme.typography['body/lg/medium'],
              },
            }),
            ...(ownerState.variant === 'header/sm/semibold' && {
              [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
                ...theme.typography['body/xxl/semibold'],
              },
            }),
          }),
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing('md-1'),
            [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
              padding: theme.spacing('sm-8'),
            },
          }),
        },
      },
      // NOTE: On mobile devices, the icon buttons should have a width of 44px and a height of 44px.
      // While other buttons should have a height of 56px. Same goes for inputs
      MuiTextField: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            ...(ownerState.className?.includes('uppercase') && {
              input: {
                textTransform: 'uppercase',
              },
            }),
            [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
              '.MuiInputBase-root': { height: '56px' },
            },
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
              ...(ownerState.className?.includes('onlyIcon')
                ? {
                    minWidth: 'unset',
                    width: '44px',
                    height: '44px',
                  }
                : { height: '56px' }),
            },
          }),
        },
      },
    },
  });
}
